.main{
    display:flex;
    height: 100vh;
}
.parappa{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    width: 35rem;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: solid 1px;
    border-color: rgb(255, 94, 0)!important;
    border-width: 1px;
    box-shadow:rgb(0 0 0 / 40%) 6px 3pt 23pt, rgb(0 0 0 / 23%) 10px 7pt 12pt;
    border-radius: 1em; 
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.imgMain{
    width: 100%;
    position: relative;
    height: 100vh;
    background-image: url(../assets/carretera.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display:flex;
    justify-content: center;
    align-items: center;
}
.formMain{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.formLogin{
    display: flex;
    flex-direction: column;
    align-items: center;
}



.formMail{
    margin-right: auto;
    margin-left: auto;
}
.bttnLogin{
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 94, 0);
   
}   
.signUp{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}
.password{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-left: 3em;
    margin-right: auto;
    
}
input{
    width: 80%;
}


.bttnLogin:hover{
    cursor: pointer;
    background-color: rgb(255, 162, 0)
}

