
.formContainer{
    left:0;
    display:flex;
    width: 100%;
    height: 13.8%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color:rgb(255, 94, 0);
    bottom: 0;
    padding:3px;
}
.chatContainer{
    display:block;
    flex-direction: column;
    background-color:white;
    align-items: center;
    width: 20vw;
    height: 500px;
    position: fixed;
    bottom:0;
    right:19rem;
    margin:0;
    border-radius:10px 10px 0px 0px !important;
    padding:0 !important;
}
.textContainer{
    width: 100%;
    height: 86.2%;
    display:flex;
    flex-direction: column;
    
}

.user{
    width: 100%;
    height: 15%;
    background-color:rgb(255, 94, 0) !important;
    color: white!important;
    display:flex;
    justify-content: space-between;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    font-size: 1.6rem;
    align-items: center;
    border-radius:10px 10px 0px 0px !important;
}
.send{
    width: 20%;
    font-size:10px;
}
.type{
    width: 80%;
}
.scroller {
  
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

.textMsg{
    width: 100%;
    height: 85%;  
}
.chatStart{
    max-width: auto;
    background-color: rgb(254, 192, 115);
    margin:2px 0px 0px 2px;
    font-size:1.6rem;
    padding:0.8rem;
    border-radius:15px;
}
.chatEnd{
    max-width: auto;
    background-color: rgb(197, 247, 151);
    margin:2px 0px 0px 2px;
    font-size:1.6rem;
    padding:0.8rem;
    border-radius:15px;
}
.me{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right:0.8rem;
    
}
.it{
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left:0.8rem;
}
.icon{
    width: 2rem;
    height: 2rem;
    margin-right: 12px;
}


