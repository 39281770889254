.notificationWrapper{
    position:relative;
  }
  
  .notificationIcon{
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    background-color: red;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
  }
  