

.tablaOfertas {
  background-color: rgb(234, 250, 8);
  border-collapse: separate;
   border-spacing-x: 10px; /* Espaciado horizontal */
   border-spacing-y: 5px; /* Espaciado vertical */
    max-width: 70%; /* Ajusta el porcentaje según tu necesidad */
    font-size: 18px; /* Reduce el tamaño de la fuente */
    text-align: center;
    
  }

  .tablaServicios {
    background-color: rgb(174, 255, 127);
    border-collapse: separate;
     border-spacing-x: 10px; /* Espaciado horizontal */
     border-spacing-y: 5px; /* Espaciado vertical */
      max-width: 70%; /* Ajusta el porcentaje según tu necesidad */
      font-size: 14px; /* Reduce el tamaño de la fuente */
      text-align: center;
    }

  .scrollable_div {
    width: 70%; /* Ajusta el ancho según tus necesidades */
    height: 300px; /* Ajusta la altura según tus necesidades */
    overflow: auto; /* Habilita la barra de desplazamiento cuando el contenido desborda */
    border: 1px solid #ccc; /* Opcional: Agrega un borde para resaltar el div */
   
    /* Puedes agregar otros estilos de diseño según tus preferencias */
  } 

  .scrollable_div::-webkit-scrollbar  {
    scrollbar-width: thin; /* Para navegadores que soportan la propiedad scrollbar-width */
    scrollbar-color: transparent transparent; /* Para Firefox */  
    width: 0.5em;
  } 

  .scrollable_div::-webkit-scrollbar-thumb  {
    background-color: transparent;
  } 

  .cupo{
    height: 20px;
    width: 50px;
  }

  .resaltado {
    background-color: rgb(255, 60, 0);
  }

  .contenedor {
    display: flex;
    background-color: rgba(255, 255, 255);
    /* opacity: 0.8; */
    width: 100%; /* Ajusta el ancho según tus necesidades */
    height: 400px; /* Ajusta la altura según tus necesidades */
    overflow: auto; /* Habilita la barra de desplazamiento cuando el contenido desborda */
    border:none; 
    padding: 0;
    margin: 0;
  }


  .contenedor::-webkit-scrollbar  {
    width: 5px; /* Ancho de la barra de desplazamiento */
  } 

  .contenedor::-webkit-scrollbar-thumb  {
    background-color: #141414;
  } 

  .contenedor::-webkit-scrollbar-track  {
    background-color: #f5f8f5;
  }

  .divOfertas {
    /* Estilos para el primer div */
    display: flex;
    justify-content: center; /* Centra horizontalmente */
     align-items: center; /* Centra verticalmente */
    margin-top: auto; /* Ajusta el valor según sea necesario para la separación */
    padding-top: 0%; /* Añade un relleno interno si es necesario */
    /* border: 1px solid #ccc;  */
    

  }


  .cabecera {
    background-color: #eee4e4; /* Fondo gris claro para la cabecera */
    padding: 10px; /* Añade un relleno interno para la cabecera */
    text-align: left; /* Alinea el texto a la izquierda */
    border: 1px solid #181717; /* Añade un borde a las celdas de la cabecera */
    font-weight: bold; /* Hace que el texto de la cabecera sea más audaz */
  }
  
 

  .boton_redondeado {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px; /* Ajusta el valor según tus preferencias */
    background-color: #4CAF50; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border: none; /* Quita el borde del botón */
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }