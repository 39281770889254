.backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(82, 88, 97, 0.4);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 500ms, opacity 500ms;
    z-index: 1000;
  }
  
  .modal {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 500ms, opacity 500ms;
  }
  
  .openModal {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 500ms;
  }  
  



  