.userLogo{
    height:3rem;
    width: 3rem;
    cursor: pointer;
}
.imgRedonda {
    width:2em;
    height:2em;
    border-radius:150px;
    background-color: rgb(255, 162, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-weight: bold;
    align-self: flex-end;
    
}

.bellIcon{
    position:absolute;
    bottom:0;
    right:0;
    color: red;
  
  }

.userAvatar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.nameText{
font-size: medium;
}

.imgProfile {
    width:5em;
    height:5em;
    border-radius:150px;
    background-color: rgb(255, 162, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 1rem;
}