.tablaOfertas {
  background-color: rgb(255, 242, 127);
  border-collapse: separate;
   border-spacing-x: 10px; /* Espaciado horizontal */
   border-spacing-y: 5px; /* Espaciado vertical */
    max-width: 70%; /* Ajusta el porcentaje según tu necesidad */
    font-size: 14px; /* Reduce el tamaño de la fuente */
    text-align: center;
  }

  .tablaServicios {
    background-color: rgb(174, 255, 127);
    border-collapse: separate;
     border-spacing-x: 10px; /* Espaciado horizontal */
     border-spacing-y: 5px; /* Espaciado vertical */
      max-width: 70%; /* Ajusta el porcentaje según tu necesidad */
      font-size: 14px; /* Reduce el tamaño de la fuente */
      text-align: center;
    }

  .scrollable_div {
    width: 70%; /* Ajusta el ancho según tus necesidades */
    height: 200px; /* Ajusta la altura según tus necesidades */
    overflow: auto; /* Habilita la barra de desplazamiento cuando el contenido desborda */
    border: 1px solid #ccc; /* Opcional: Agrega un borde para resaltar el div */
  
    /* Puedes agregar otros estilos de diseño según tus preferencias */
  } 

  .cupo{
    height: 20px;
    width: 50px;
  }

  .resaltado {
    background-color: rgb(255, 60, 0);
  }

  .contenedor {
    display: flex;
    width: 90%; /* Ajusta el ancho según tus necesidades */
    height: 500px; /* Ajusta la altura según tus necesidades */
    overflow: auto; /* Habilita la barra de desplazamiento cuando el contenido desborda */
    border: 1px solid #ccc; /* Opcional: Agrega un borde para resaltar el div */
  }


  .divOfertas {
    /* Estilos para el primer div */
    margin: 10px; /* Ajusta el valor según sea necesario para la separación */
    padding: 10px; /* Añade un relleno interno si es necesario */
    border: 1px solid #ccc; /* Añade un borde de 1 píxel sólido de color gris claro */

  }
  
  .divServicios {
    /* Estilos para el segundo div */
   
    margin: 10px; /* Ajusta el valor según sea necesario para la separación */
    padding: 10px; /* Añade un relleno interno si es necesario */
    border: 1px solid #ccc; /* Añade un borde de 1 píxel sólido de color gris claro */
  }