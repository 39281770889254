.cardTop{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.cardBottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.cardInput{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.delText{
    color:orange
}
.delText:hover{
    color:red
}