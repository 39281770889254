

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.goTrip{
  border-color:rgb(255, 94, 0);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  height: 1.5;
  color: rgb(255, 94, 0);
  background: transparent;
  border-width: 1px;
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
 .goTrip:hover{
  color : #fff;
  background: rgb(255, 51, 0);
  border-color: transparent;
 }
 .goTrip:focus{
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
 }
 .goTrip:active{
    color: #fff;
    background: rgb(255, 51, 0);
    border-color: rgb(255, 94, 0);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
 }
 .goTrip:disabled{
    color: #fff;
    background: rgb(255, 94, 0);
    border-color: rgb(255, 94, 0);
    opacity: 0.65;
}


.goTripReverse{
  border-color:white;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  height: 1.5;
  color: white;
  background: transparent;
  border-width: 1px;
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
 .goTripReverse:hover{
  color : #fff;
  background: rgb(255, 51, 0);
  border-color: transparent;
 }
 .goTripReverse:focus{
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
 }
 .goTripReverse:active{
    color: #fff;
    background: rgb(255, 51, 0);
    border-color: rgb(255, 94, 0);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
 }
 .goTripReverse:disabled{
    color: #fff;
    background: rgb(255, 94, 0);
    border-color: rgb(255, 94, 0);
    opacity: 0.65;
}


  #basic-nav-dropdown::after{
    display: none; 
 }

 .tripSummary{
  width: 100%;
  margin:2rem;
  border: solid 1px;
  border-color: rgb(255, 94, 0)!important;
  border-width: 1px;
  box-shadow:1px ;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 1em;
  border-radius: 16px;
    box-shadow:rgb(0 0 0 / 16%) 3px 2pt 3pt, rgb(0 0 0 / 8%) 0px 7pt 10pt;
    list-style-type: none;
    transition: box-shadow 200ms ease-in 0s;
}*, ::after, ::before {
    box-sizing: border-box;
}
.tripSummary:hover{
background-color: antiquewhite;
}

.notiSummary{
    width: 60%;
    border: solid 1px;
    border-color: rgb(255, 94, 0)!important;
    border-width: 1px;
    box-shadow:1px ;
    margin: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 1em;
    border-radius: 16px;
      box-shadow:rgb(0 0 0 / 16%) 3px 2pt 3pt, rgb(0 0 0 / 8%) 0px 7pt 10pt;
      list-style-type: none;
      transition: box-shadow 200ms ease-in 0s;
  }*, ::after, ::before {
      box-sizing: border-box;
  }
  .notiSummary:hover{
  background-color: antiquewhite;
  }
  .noMargin{
  width: 90% !important;
  margin: 0 !important;
  }