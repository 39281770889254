.container{
    display:flex;
}
.background{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgb(255, 94, 0);
    color:white;
    width: 10em;
    height: 2.2em;
    font-size: 30px;
    font-weight: bold;
    position:fixed;
    bottom:0;
    right:0.1em;
}
.dropupContent{
    display:none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    z-index: 1;
    color:black;
    width: 10em;
    height: 27rem;
    bottom:4.2rem;
    border-radius:10px 10px 0px 0px;
}
.dropupContent div {
    color: black;
    background-color: #f1f1f1;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 1.6rem;
    border-radius:10px
}

.background:hover .dropupContent {
    display: block;
}
.dropupContent div :hover {
    background-color: rgba(238, 157, 18, 0.858);
}
.diseñoChatUser{
    display:flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}
.icon{
    width: 2rem;
    height: 2rem;
    margin-right: 12px;
    /* height: 10vw; */
}
.name{
    margin-left: 100px;
}
