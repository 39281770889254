.parappa{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 25rem;
    margin: 0;
    background-color: white;
    border: solid 1px;
    border-color: rgb(255, 94, 0)!important;
    border-width: 1px;
    box-shadow:rgb(0 0 0 / 40%) 6px 3pt 23pt, rgb(0 0 0 / 23%) 10px 7pt 12pt;
    border-radius: 1em; 
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}



.newInput{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 800px;
    width: 60rem;
    border-radius: 20px;
    box-shadow: 0px 0px 7px rgb(0, 0, 0, 0.5);
    margin: 50px auto auto auto;
    background-image: linear-gradient(-180deg, rgba(255,255,255,1) 31%, rgba(255,255,255,0) 100%), 
    url(../assets/CocheEnPlaya.png);
    background-size: cover;
}

h3{
    font-size: 20px;
	text-transform: uppercase;
	color: #384c5f;
	margin-bottom: 20px;
}

.textbox{
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.icon{
        height: 1.5rem;
        width: 1.5rem;
        background-color: red;
        padding: 4px;
        position: absolute;
        box-sizing:border-box;
        top:50%;
        left:2px;
        transform: translateY(-50%);
}

.wrapper{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}
.password{
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.emptyfield{
    height: 1em;
    font-family: 'Raleway', sans-serif;
    color: red;
}
input, select{
    height:50px;
    width:100%;
    border-radius:12px;
    border:rgba(0,0,0,.3) 2px solid;
    box-sizing:border-box;
    padding:10px;
    margin-bottom:5px;
  }


.submit{
    color: white;
    height: 50px;
    width: 100px;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    border: 0px;
    border-radius: 12px;
    background-color: rgb(255, 94, 0);
    margin: 10px 0px 10px 0px;
}

.submit:hover{
    background-color: rgb(249, 218, 187);
    cursor: pointer;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fechas{
    margin:0.5em !important;
    border-radius: 1em !important;
  }