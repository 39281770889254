.buscarViajeMap{
    display: flex;
    height: 300px;
    width: 60%;
    margin-left: 20%;    
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    background: rgb(65, 122, 155);
    
}

.origenInput{
    display: flex;
    height: 40px;
    width: 60%;
    margin-left: 20%;    
    /* color: white; */
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    text-align: center;
    background: rgb(183, 209, 224);
    border-color: rgb(65, 122, 155);
    background-image: url('../components/assets/iconosearch.png'); /* Ruta de la imagen del icono */
   background-repeat: no-repeat;
   background-position: 10px center; /* Ajusta la posición del icono */
   background-size: 20px; /* Ajusta el tamaño del icono */
}

.destinoInput{
    
    display: flex;
    height: 40px;
    width: 60%;
    margin-left: 20%;    
    /* color: white; */
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    text-align: center;
    background: rgb(183, 209, 224);
    border-color: rgb(65, 122, 155);
    background-image: url('../components/assets/iconosearch.png'); /* Ruta de la imagen del icono */
    background-repeat: no-repeat;
    background-position: 10px center; /* Ajusta la posición del icono */
    background-size: 20px; /* Ajusta el tamaño del icono */

}

.camposEditables{
    
    display: flex;
    height: 40px;
    width: 60%;
    margin-left: 20%;    
    /* color: white; */
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    text-align: center;
    background: rgb(248, 248, 248);
    border-color: rgb(5, 7, 0);
    background-image: url(''); /* Ruta de la imagen del icono */
    background-repeat: no-repeat;
    background-position: 10px center; /* Ajusta la posición del icono */
    background-size: 20px; /* Ajusta el tamaño del icono */

}

.btnTransporte{
    display: flex;
    height: 40px;
    width: 30%;
    margin: 0 10px;
    /* margin-left: 35%;     */
    /* color: white; */
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    text-align: center;
    background: #4CAF50;
    border-color: rgb(41, 145, 67);
    border-radius: 5%;
   
}

.centrar_texto {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.labelOrigen {
    display: flex;
    /* height: 40px; */
    /* width: 60%; */
    margin-right: 20%;    
    /* color: white; */
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    /* background: rgb(183, 209, 224); */
    /* border-color: rgb(65, 122, 155); */
    
  }

.letrero{
    display: flex;

}

/* .inputContainer{
    display: flex;
    margin-right: 30%;
   
}   */

/* .geocoder-input{
    display: flex;
    margin-right: 30%;
}

.geocoder-container{
    display: flex;
    margin-right: 30%;
} */