.title{
    margin: auto;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px 10px -12px rgb(148, 148, 148);
    padding: 10px;
    font-size: 30px;
    color: rgb(54, 52, 50);
}

.datawrapper{
    margin: auto;
    height: 100%;
    width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.fotoWrapper{
    margin-right: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;margin-right: 2.5rem;

}

.buttonWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fileInput{
    visibility:hidden
}

.fotoButtons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

strong, .userData{
    color: rgb(124, 124, 124);
    font-size: 20px;
}
.userData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 2.5rem;
    font-size: 20px;
    color: orange
}

.editButton{
    border-style: none;
    background-color: rgba(255, 255, 255, 0);
    color: orange;
    border-radius: 5px;
}

.editButton:hover{
    background-color: rgb(255, 164, 78);
    color: white;
    cursor: pointer;
}

.additionalData{
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2.5rem;
    font-size: 20px;
    color: orange
}

.imgRedonda {
    width:5em;
    height:2em;
    border-radius:150px;
    background-color: rgb(255, 162, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-weight: bold;
    
}