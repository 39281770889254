.container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.half{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
}