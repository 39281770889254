
.parappa{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.tittle{
    font-family: 'Raleway', sans-serif;
    font-size:2em;
    font-weight: bold;
    height: 20%;
    display:flex;
    justify-content: center;
    margin-top:30px;

}
.tittleText{
    background-color: #FF7A59 ;
    border-radius: 20px;
    height: 100px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.body{
    font-family: 'Raleway', sans-serif;
    height: 80%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:16rem;
}
.trip{
    height: 35em;
    width: 50em;
    margin:15px;
    border-radius: 20px;
    box-shadow: 0px 0px 7px rgb(0, 0, 0, 0.5);
    display:flex;
    flex-direction: column;
}
.location{
    width:70%;
    display:flex;
    flex-direction: row;
}
.tripDraw{
    width: 10%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tripLocation{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
}
.locationText{
    font-family: 'Raleway', sans-serif;
    margin:2.8rem;
}
.circle{
        background: #FF7A59;
        width: 20px;
        height: 20px;
        border-radius: 50%;
}
.line{
    border-left: 6px solid #FF7A59;
    height: 100px;
}
.details{
    width: 30%;
    display:flex;
    flex-direction: column;  
    justify-content: center;
    align-items: center; 
}
.price{
   margin-top:20px;
   font-family: 'Raleway', sans-serif;
   height:30%;
   font-size: 2em;
   justify-content: center;
   align-items: center;
}
.seats{
    font-family: 'Raleway', sans-serif;
    font-size:1.3em;
}
.user{
    height: 70%;
    display:flex;
    justify-content: center;
    align-items: center;
}
img{
    width: 100px;
    height: 100px;
}
.bodyData{
    height:90%;
    display:flex;
    width: 100%;
}
.dateDate{
    background-color: #FF7A59;
    border-radius: 20px 20px 0px 0px;
    height: 10%;
    display:flex;
    flex-direction: row;
    width: 100%;
}
.dateText{
    width: 90%;
    font-family: 'Raleway', sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:1rem;
    font-weight: bold;
}
.cross{
    width: 10%;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    align-items: center;
}
.crossText{
   margin-top:0px;
   margin-left:45px;
   height: 25px;
   width: 25px;
}

.notFound1{
    align-self: center;
    margin-top: 10%;
}
.notFound2{
    align-self: center;
    margin-top: 10%;
}
.notFound2:hover{
    color: orange;
}

.wrapTrip{
    height: 100%;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
}