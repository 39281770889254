/* modal.css */
.ReactModal__Overlay {
    /* Estilos del fondo del modal */
    z-index: 10000;
    opacity: 0.9;
    background-color: gray;
  }
  
  .ReactModal__Content {
    /* Estilos del contenido del modal */
    z-index: 1001;
    width: 50%;
    height: 70%;
    margin: auto;
  }
  
.Texto{
font-size: 10px;
font-style: italic;
}
