.viaje{
    display: flex;
    flex-direction: column;
    width: 35%;
    border-radius: 25%;
    border: 1px 

}

.cardTop{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 75%;
}
.cardBottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 25%;
}

.userIcons{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    width: 25%;
    
}

.timeTable{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:13% ;
}

.leftwrapper{
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 70%;
}

.locations{
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: baseline;
    justify-content: flex-start;
    margin-left: 2em;
}

.location_graph_top{
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
    padding-left: 0.5em;
    justify-content: flex-start;
}
.location_graph_top p{
    margin-left: 1em;
    margin-bottom: 0px;

}
.location_graph_bottom{
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
    padding-left: 0.5em;
    justify-content: flex-start;
}
.location_graph_bottom p{
    margin-left: 1em;
    margin-bottom: 0px;
}
.location_graph_mid{
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start
}
.location_text{
    display: flex;
    flex-direction: column;
}
.pricing{
    width: 30%;
    align-self: flex-start;
    display: flex;
    flex-direction: row-reverse
}