.listWrap{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    flex-direction: column;
}

.itemWrap{
    height:3em ;
    width: 100%;
    display: flex;
    align-items:flex-start;
    justify-content: space-evenly;
    justify-items: flex-start;
}