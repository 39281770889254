
.modalBackground{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40rem;
    width: 40rem;
    margin: 0;
    background-color: white;
    box-shadow:rgb(0 0 0 / 40%) 6px 3pt 23pt, rgb(0 0 0 / 23%) 10px 7pt 12pt;
    border-radius: 1em; 
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.parappa{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: rgb(255, 94, 0);
    align-self: center;
    margin: auto;

}


.cardTop{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    position: relative;
}
.timeTable{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:13% ;
}

.leftwrapper{
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 70%;
}

.locations{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: baseline;
    justify-content: flex-start;
    margin-left: 2em;
}

.location_graph_top{
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
    padding-left: 0.5em;
    justify-content: flex-start;
}
.location_graph_top p{
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 0px;

}
.location_graph_bottom{
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
    padding-left: 0.5em;
    justify-content: flex-start;
}
.location_graph_bottom p{
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 0px;
}
.location_graph_mid{
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 4.5em;
}
.location_text{
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.onClickTop{
    position: absolute;
    height: 100%;
    z-index:10;
    width: 100%;
}
.onClickBottom{
    position: absolute;
    height: 100%;
    z-index:10;
    width: 100%;
    top:50%
}
.clickText:hover{
    color: red;
    opacity: 0.5;
}
.onClickBottom:hover{
    background-color: antiquewhite;
    opacity: 0.5;
}


.barra{
    background-color: rgb(255, 94, 0);
    margin: 1em;
    height: 0.5em;
    width: 30%;
    line-height: 1;
    color: rgb(255, 94, 0);
    opacity: 0.8;
    border-radius: 16px;
    box-shadow:rgb(0 0 0 / 16%) 3px 2pt 3pt, rgb(0 0 0 / 8%) 0px 7pt 10pt;
    
}

.userSection{
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userIcons{
    display: flex;
}

.openChat{
    margin-right: 1rem;
    align-self: center;
}

.warning{
    color:red
}