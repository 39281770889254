.navBarWrapper{
    width: 100vw;
    height: 70px;
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
}

.addBottomMargin{
  margin-bottom: 2rem;
}

.logo{
 width: 60vw;
 font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    color: rgb(255, 162, 0);
}

.brand{
  cursor:pointer
}

.logoSvg{
    height:3rem;
    width: 3rem;
    text-decoration: none;
    cursor:pointer
}

nav{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.listNav{
    width: 50vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    list-style: none;
}
.ml{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.userLogo{
    height:3rem;
    width: 3rem;
    cursor: pointer;
}
.navbardiv{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 25px;
  color: rgb(255, 162, 0);
  padding-left: 20px;
}
#navbar{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 25px;
  color: rgb(255, 162, 0);
  padding-left: 20px;
  border-bottom: solid 1px;
  border-color: rgb(255, 94, 0)!important;
  border-width: 1px;
}



.dropdownContent {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    margin-left:-50px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);  
    z-index: 1;
  }

  .dropdownContent p {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  .dropdown:hover .dropdownContent {
    display: block;
    background-color: rgb(255, 255, 255);
  }

  .navbardiv:hover{
    cursor: pointer;
    text-decoration: none;
  }
  .dropdownContent p:hover {
    background-color: rgb(255, 227, 176);
    cursor:pointer;
  }
.imgRedonda {
    width:3rem;
    height:3rem;
    border-radius:150px;
    background-color: rgb(65, 122, 155);
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-weight: bold;
    
}

.glass{
  color: rgb(65, 122, 155);
  
}

.wrapperUserAvatar{
  position:relative;
}

