.main{
    display:flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}
.tittleBody{
    display:flex;
    justify-content:center;
    align-items: center;
    height: 20%;
    width: 100%;
    background-color: aliceblue;
}
.tittle{
    width: 30rem;
    height: 7rem;
    font-family: 'Raleway', sans-serif;
    font-size:2em;
    font-weight: bold;
    background-color: #FF7A59 ;
    border-radius: 20px;
    display:flex;
    justify-content: center;
    align-items: center;   
}

.userBody{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    height: 80%;
    width: 100%;
}
.user{
    height: 35em;
    width: 50em;
    margin:15px;
    border-radius: 20px;
    box-shadow: 0px 0px 7px rgb(0, 0, 0, 0.5);
    display:flex;
    flex-direction: column;
}
.header{
    background-color: #FF7A59;
    border-radius: 20px 20px 0px 0px;
    height: 10%;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width: 100%;
}

.crossText{
    height: 25px;
    width: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    align-items: center;
 }
 .cross{
    margin-top:1px;
    height: 20px;
    width: 20px;
    margin-left:-5px;
 }
 .body{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;
 }
 .fotoUser{
    width: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
 }
 .data{
    width:50%;
    display:flex;
    flex-direction: column;
 }
 .dataName{
    height: 50%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 }
 .name{
    font-family: 'Raleway', sans-serif;
    font-size:4em;
    font-weight: bold; 
 }
 .dataInfo{
    height: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
 }
 .info{
    font-family: 'Raleway', sans-serif;
    font-size:1em;
    font-weight: bold; 
 }
 .userLogo{
    height: 200px;
    width: 200px;
}
